import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import constructionSVG from '../assets/undraw_online_resume_re_ru7s.svg';

const theme = createMuiTheme({
    typography: {
      fontFamily: 'Montserrat',
    }
});

const App = () => {
    const mobile = useMediaQuery(useTheme().breakpoints.down('xs'));
    const tab = useMediaQuery(useTheme().breakpoints.down('md'));
    const uhd = useMediaQuery(useTheme().breakpoints.up('xl'));

    const resp_img = { 
        marginTop: tab ? '20%' : '10%',
        width: uhd ? '30%' : mobile ? '90%' : '50%',
        marginLeft: mobile ? '7.5%' : '0'
    };

    return (
        <ThemeProvider theme={theme}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', alignItems: 'center'}}>
                <img
                    src={constructionSVG}
                    alt='web construction illustration'
                    style={resp_img}
                />
                <Typography style={{ paddingTop: 20, fontSize: 18 }}>CoinTrade.ai Website coming soon.</Typography>
                <Typography style={{ paddingTop: 5, fontSize: 18 }}>Watch this space!</Typography>
            </div>
        </ThemeProvider>
    );
};

export default App;